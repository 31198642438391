import { useCurrentUser } from "./useCurrentUser";
import { datadogRum } from "@datadog/browser-rum";
import { useEffect } from "react";

export const useDatadogContext = () => {
  const { permissions, userId, selectedPPCCampaign, selectedPPLCampaign } =
    useCurrentUser();
  const ppcCampaignId = selectedPPCCampaign?.id;
  const pplCampaignId = selectedPPLCampaign?.id;

  useEffect(() => {
    if (ppcCampaignId)
      datadogRum.setGlobalContextProperty("ppc_campaign_id", ppcCampaignId);
    else datadogRum.removeGlobalContextProperty("ppc_campaign_id");

    if (pplCampaignId)
      datadogRum.setGlobalContextProperty("ppl_campaign_id", pplCampaignId);
    else datadogRum.removeGlobalContextProperty("ppl_campaign_id");

    if (userId) {
      datadogRum.setGlobalContextProperty("user_id", userId);
      datadogRum.setGlobalContextProperty(
        "permissions",
        Object.entries(permissions || {}).reduce((result, [key, value]) => {
          return {
            ...result,
            [key]: {
              hasAccess: value.hasAccess,
              resourceCount: value.resources.length,
            },
          };
        }, {}),
      );
    } else {
      datadogRum.removeGlobalContextProperty("user_id");
      datadogRum.removeGlobalContextProperty("permissions");
    }
  }, [userId, permissions, ppcCampaignId, pplCampaignId]);
};
