import { useState, useEffect } from "react";
import { Inline, Text, Icon } from "~/components/vendorUI";
import getEnv from "~/utils/env";
import { css } from "ui/css";

const barCSS = css({
  background: "red",
  backgroundSize: "400% 400%",
  flexWrap: "wrap",
  position: "relative",
  textShadow: "0 1px 1px #00000033",
  padding: "10px 20px",
});

const closeIcon = css({
  cursor: "pointer",
});

export const ProductionBanner = ({ session }) => {
  const [toggleBanner, setToggleBanner] = useState(
    sessionStorage.getItem("showProdBanner") === null
      ? { banner: true, vendorId: session.enterpriseVendorId }
      : JSON.parse(sessionStorage.getItem("showProdBanner")),
  );

  useEffect(() => {
    if (
      sessionStorage.getItem("showProdBanner") === null ||
      JSON.parse(sessionStorage.getItem("showProdBanner")).banner === true
    ) {
      sessionStorage.setItem("showProdBanner", JSON.stringify(toggleBanner));
    }
  }, [toggleBanner]);

  if (getEnv().REACT_APP_ENV !== "production") return null;

  return toggleBanner.banner ? (
    <Inline className={barCSS} align="center" distribute="center">
      <Text size="14px" color="inverted" weight="regular">
        <strong>Production environment</strong>: Changes impact real data
      </Text>

      <Icon
        name="CircleCross"
        size="16px"
        color="white"
        data-testid="close-icon"
        className={closeIcon}
        onClick={() => {
          setToggleBanner({
            banner: false,
            vendorId: session.enterpriseVendorId,
          });
        }}
      />
    </Inline>
  ) : null;
};

export default ProductionBanner;
