import type { ReactNode } from "react";
import { Tooltip, DatePicker, ToastProvider } from "~/components/vendorUI";
import { useTranslation } from "~/utils";

type LocaleType =
  | "de-DE"
  | "en-US"
  | "es-ES"
  | "fr-FR"
  | "it-IT"
  | "ja-JP"
  | "nl-NL"
  | "pt-BR";

export const Providers = ({ children }: { children: ReactNode }) => {
  const { i18n } = useTranslation();

  return (
    <ToastProvider locale={i18n.language as LocaleType}>
      <Tooltip.Provider>
        <DatePicker.Provider locale={i18n.language}>
          {children}
        </DatePicker.Provider>
      </Tooltip.Provider>
    </ToastProvider>
  );
};
