import { datadogRum } from "@datadog/browser-rum";
import type { LoaderType } from "app/routes/__vendor-portal";
import type { UserDataOrganization } from "app/types/userData";
import {
  asyncWithLDProvider,
  type LDContext,
} from "launchdarkly-react-client-sdk";
import getEnv from "~/utils/env";

type Params = {
  userId: string;
  employeeId?: string;
  organizations?: UserDataOrganization[];
  vendorId?: string;
  ppcCampaignId?: string;
  salesChannel?: string;
};

const createAnonymousLDContext = (): LDContext => {
  return {
    kind: "user",
    anonymous: true,
  };
};

export const createLDContext = ({
  userId,
  organizations = [],
  employeeId,
  ...params
}: Params): LDContext => {
  if (!userId) {
    return createAnonymousLDContext();
  }

  const user = {
    internal: Boolean(employeeId),
    key: userId,
    ...params,
  };

  if (!organizations.length) {
    return {
      kind: "user",
      user,
    };
  }

  return {
    kind: "multi",
    user,
    // adding the first organization because we cannot add an array to the context
    organization: {
      key: organizations[0].id,
      enterpriseVendorId: organizations[0].enterpriseVendorId,
    },
  };
};

export const createLaunchDarklyProvider = async (
  session: LoaderType["session"],
) => {
  const ldContext = createLDContext({
    userId: session?.userId,
    employeeId: String(session?.employeeId),
    organizations: session?.organizations,
    vendorId: String(session?.enterpriseVendorId),
    ppcCampaignId: session?.selectedPPCCampaign?.id
      ? String(session?.selectedPPCCampaign.id)
      : undefined,
    salesChannel: session.campaignSummary?.salesChannel,
  });

  return await launchdarklyProvider(ldContext);
};

const launchdarklyProvider = (context?: LDContext) =>
  asyncWithLDProvider({
    clientSideID: getEnv().REACT_APP_LAUNCH_DARKLY_CLIENT_ID || "",
    options: {
      inspectors: [
        {
          type: "flag-used",
          name: "dd-inspector",
          method: (key: string, detail) => {
            datadogRum.addFeatureFlagEvaluation(key, detail.value);
          },
        },
      ],
      // disables real-time updates of feature flags
      streaming: false,
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
    context,
  });
